import React, { useState, useEffect } from 'react';
import '../App.css';  // Ensure you import the CSS file

const Progress = () => {
  const [progress, setProgress] = useState(() => Math.floor(Math.random() * 51) + 25);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => (prev < 95 ? prev + 1 : prev));
    }, Math.floor(Math.random() * (60000 - 1000 + 1)) + 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      minHeight: '100vh',
      justifyContent: 'center',
      backgroundColor: '#f0f0f0',
      fontFamily: 'Arial, sans-serif'
    }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <div className="spinner"></div>
      </div>
      <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Loading...</h1>
      <p style={{ textAlign: 'center', marginBottom: '40px', color: '#666' }}>Please wait while we load your data.</p>
      <div style={{
        width: '80%',
        backgroundColor: '#e0e0e0',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '20px'
      }}>
        <span style={{
          position: 'absolute',
          color: '#fff',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          fontWeight: 'bold'
        }}>{progress}%</span>
        <div style={{
          width: `${progress}%`,
          backgroundColor: '#4287f5',
          padding: '15px 0',
          transition: 'width 1s ease-in-out',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
        }}>
        </div>
      </div>
      <div style={{
        width: '80%',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxSizing: 'border-box',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
        marginBottom: '20px'
      }}>
        <p style={{ backgroundColor: '#e0e0e0', height: '20px', marginBottom: '10px', borderRadius: '4px' }}></p>
        <p style={{ backgroundColor: '#e0e0e0', height: '20px', marginBottom: '10px', borderRadius: '4px', width: '80%' }}></p>
        <p style={{ backgroundColor: '#e0e0e0', height: '20px', marginBottom: '10px', borderRadius: '4px', width: '90%' }}></p>
      </div>
      <div style={{
        width: '80%',
        backgroundColor: '#fff',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
      }}>
        <p style={{ backgroundColor: '#e0e0e0', height: '20px', marginBottom: '10px', borderRadius: '4px' }}></p>
        <p style={{ backgroundColor: '#e0e0e0', height: '20px', marginBottom: '10px', borderRadius: '4px', width: '70%' }}></p>
        <p style={{ backgroundColor: '#e0e0e0', height: '20px', borderRadius: '4px', width: '60%' }}></p>
      </div>
    </div>
  );
}

export default Progress;
